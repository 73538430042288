<template>
  <div class="w-full p-4 md:p-0 md:grid grid-cols-6 gap-4">
    <div id="main-content" class="col-span-4">
      <PlaceAbout :bio="bio" :userIsAdmin="true" :editMode="isCreator"  :short="short"
        :shortBio="(bio)?bio.substring(0, 500):null"></PlaceAbout>
        <PlaceFeaturedPhotos :images="Images" :editMode="isCreator" :userIsAdmin="isCreator"></PlaceFeaturedPhotos>
        <PlaceProductions :editMode="isCreator" :userIsAdmin="isCreator" :changeComponent="changeComponent" :navigation="navigation"  ></PlaceProductions>
        <PlaceSpaces :bio="bio" :userIsAdmin="true" :editMode="isCreator"  :short="short"
        :shortBio="(bio)?bio.substring(0, 500):null"></PlaceSpaces>
    </div>
    <div id="side-content" class="col-span-2 flex flex-col gap-4">
      <PlaceGeneral :general="companyGeneralInfo" :editMode="isCreator"></PlaceGeneral>
      <ResidentCompanies :general="companyGeneralInfo" :editMode="isCreator"></ResidentCompanies>
      <PlaceAffiliation :general="companyGeneralInfo" :editMode="isCreator"></PlaceAffiliation>
    </div>
  </div>

</template>

<script>
import { computed,ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import PlaceAbout from "@/components/places/components/placeOverview/about/PlaceAbout";
// import CompanyAwards from "../components/companyOverview/awards/CompanyAwards";
import PlaceFeaturedPhotos from "@/components/places/components/placeOverview/featuredPhotos/PlacesFeaturedPhotos";
import PlaceGeneral from "@/components/places/components/placeOverview/general/CompanyGeneral";
// import PlacePeople from "@/components/places/components/placeOverview/people/CompanyPeople";
import PlaceProductions from "@/components/places/components/placeOverview/production/PlaceProductions";
import PlaceAffiliation from "@/components/places/components/placeOverview/Affiliations/PlaceAffiliation";
import ResidentCompanies from "@/components/places/components/placeOverview/ResidentCompanies/ResidentCompanies";
import PlaceSpaces from "@/components/places/components/placeOverview/Spaces/PlaceSpaces";
// import PreviewRow from'@/components/widgets/previewRow/PreviewRow.vue';
export default {
  name: "Overview",
  components: {
    PlaceAbout,
    PlaceGeneral,
    PlaceFeaturedPhotos,
    // CompanyAwards,
    PlaceProductions,
    // PlacePeople,
    PlaceAffiliation,
    ResidentCompanies,
    PlaceSpaces
  },
  
  props: {
    company: {
      type: Object,
    },
   changeComponent:Function,
    navigation:{type:Array},
    editMode: {
      type: Boolean,
      required: true,
    },
  },
   
  
   setup() {
    const store = useStore();
    const router = useRouter();
    // const route = useRoute()
    const short = ref(true);
  
    return {

      short,
      companyGeneralInfo: computed(() => store.getters.getCompanyGeneral),
      awards: computed(() => store.getters.getCompanyAwards),
      companyMembers: computed(() => store.getters.getTableFormattedCompanyMembers),
      productions: computed(() => store.getters.getProductions),
      Images: computed(() => store.getters.getCompnayImages),
      onClick(id) {
        router.push({ name: "Venue", params: { id } });
      },
      isCreator: computed(
        () => store.getters.getId === store.getters.getCompanyCreatedBy
      ),
      bio:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." 
    };
  }
};
</script>
