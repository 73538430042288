<template>
    <ContentWrapper :editMode="editMode" @onClick="editActive = true" :content="userIsAdmin" :showTitleIcon="true"
        title="About" titleIcon="UserIcon">
      
        <div v-if="lengthToggle">
      <p class="pt-1 text-sm sentence-case whitespace-pre-wrap">{{this.$props.shortBio}} {{ renderEllipses(bio) }} </p>
      <button v-if="isBioLong(bio)" class="w-full text-right items-center mt-2.5  text-sm font-medium rounded underline bg-white focus:outline-none dark:bg-primaryDark dark:text-white grey-slate text-sm" @click="lengthToggle = false">
          See more
      </button>
    </div>
    <div v-if="!lengthToggle">
      <p class="pt-1 text-sm sentence-case whitespace-pre-wrap">{{ bio }}</p>
      <button v-if="isBioLong(bio)" class="w-full text-right items-center mt-2.5 shadow-sm text-sm font-medium rounded underline bg-white focus:outline-none dark:bg-primaryDark dark:text-white grey-slate text-sm" @click="lengthToggle = true">
          See less
      </button>
    </div>
        <div @click="editActive = userIsAdmin" v-if="!bio" class="flex justify-center items-center w-full ">

            <label for="text"
                class="flex flex-col justify-center items-center w-full h-28 bg-gray-100 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer  dark:bg-gray-700  dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 dark:bg-primaryDark">
                <div class="flex flex-col justify-center items-center  ">
                    <p class="text-black text-xl dark:text-gray-400"><span class="" v-if="userIsAdmin == true">Tell
                            us about your
                            company</span></p>
                </div>

            </label>
        </div>
    </ContentWrapper>
    <CompanyAboutEdit v-if="editActive" :bio="bio" :editActive="editActive" @onClose="editActive = false" />
</template>

<script>
import ContentWrapper from '@/components/widgets/ContentWrapper'
import CompanyAboutEdit from './PlaceAboutEdit'
export default {
    name: "PlaceAbout",
    components: {
        ContentWrapper,
        CompanyAboutEdit
    },
    data() {
        return {
            lengthToggle: this.short,
            editActive: false
        }
    },
    props: {
        bio: {
            type: String,
            required: true
        },
        editMode: {
            type: Boolean,
            required: true
        },
        userIsAdmin: {
            type: Boolean,
            default: false
        },short: {
      type: Boolean,
      required: true,
    },
    shortBio: {
        type:String,
      required: true,
    },
    },
    methods: {
    
    isBioLong(bio) {
      //  This prevents rendering the 'See More/Less' buttons
      //  when they are not needed.
      return bio?.length > 500;
    },
    renderEllipses(bio) {
      //  Shows ellipses if bio is longer than 500 chars
      if (this.isBioLong(bio)) {
        return "..."
      } else {
        return;
      }
    },
  },
}
</script>