<template>
  <ContentWrapper @onClick="editActive = true" :editMode="editMode" :showTitleIcon="false"  v-if="companyMembers.length >0" title="Resident Companies"
    titleIcon="UserIcon">
    <div class="about_info dark:text-white mt-5">
      <ul class="max-w-md ">
        <li class="py-3 sm:py-4" v-for="member in companyMembers" :key="member.id">
            <div class="flex items-center space-x-4">
              <div class="flex-shrink-0">
                  <img class="w-12 h-12 rounded-full" :src="member.image" alt="{{member.name}}">
              </div>
              <div class="flex-1 min-w-0">
                  <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                    {{member.name}}
                  </p>
                  <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                    {{member.subtitle}}
                  </p>
              </div>
            </div>
        </li>
      </ul>
    </div>
  </ContentWrapper>

</template>

<script>
import ContentWrapper from "@/components/widgets/ContentWrapper";
import { computed } from "vue";
import { useStore } from 'vuex'
import { useRoute } from "vue-router";
export default {
  name: "ResidentCompanies",
  components: {
    ContentWrapper,
    
  },
  data() {
    return {
      editActive: false,
      date: this.general.dissolved,
    }

  },
  props: {
    general: {
      type: Object,
      required: true,
    },

    editMode: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute()
    store.dispatch('fetchCompanyMembers',{ first:4, after: "", before: "", searchFilter: "", sortBy: "", companyId:route.params.id})
    store.dispatch('fetchCompanies', {first:100, after:"", before:"", searchFilter:"", sortBy:'', name:'', level:'', type:''})
    return{
      store,
      getCompanies: computed(() => store.getters.getCompanies),
      peopleRangeFilters: computed(() => store.getters.getPeopleRangeFilters),
      companyMembers: [{'name':'A local theatre company','subtitle':'Theatre company','image':'https://s3.eu-west-1.amazonaws.com/showdeck.io/static/placeholders/venue_placeholder.png'},{'name':'A local dance company','subtitle':'Dance company','image':'https://s3.eu-west-1.amazonaws.com/showdeck.io/static/placeholders/venue_placeholder.png'}],
    }
  },
};
</script>
