<template>
PlaceSpace
</template>

<script>
export default {
  name: "PlaceSpace"
}
</script>

<style scoped>

</style>