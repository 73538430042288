<template>
    <ContentWrapper :editMode="editMode" @onClick="editActive = true" :content="userIsAdmin" :showTitleIcon="true"
        title="Spaces" titleIcon="HomeIcon">
      <div class="pt-3 grid grid-cols-1 gap-2 gap-x-4 md:grid-cols-2 xl:grid-cols-4">
        <div class="relative flex flex-col bg-clip-border  bg-transparent text-gray-700 shadow-none text-center" v-for="member in spacesArr" :key="member.id">
          <img :src="member.image" alt="Ryan Tompson" class="inline-block relative object-cover object-center  h-28 w-full ">
          <h6 class="block antialiased tracking-normal font-sans text-base font-semibold leading-snug text-blue-gray-900 mt-5 mb-1">{{member.name}}</h6>
          <p class="block antialiased font-sans text-sm leading-relaxed font-normal text-blue-gray-500">{{member.content}}</p>
          <p class="block antialiased font-sans text-sm leading-relaxed font-normal text-blue-gray-500">{{member.capacity}}</p>
        </div>
      </div>
    </ContentWrapper>
  
</template>

<script>
import ContentWrapper from '@/components/widgets/ContentWrapper'
export default {
    name: "PlaceSpaces",
    components: {
        ContentWrapper,
    
    },
    data() {
        return {
            lengthToggle: this.short,
            editActive: false
        }
    },
    props: {
        bio: {
            type: String,
            required: true
        },
        editMode: {
            type: Boolean,
            required: true
        },
        userIsAdmin: {
            type: Boolean,
            default: false
        },short: {
      type: Boolean,
      required: true,
    },
    shortBio: {
        type:String,
      required: true,
    },
    },
    setup() {
         return{
          spacesArr:[{'id':1,'name':'Main House','image':'https://s3.eu-west-1.amazonaws.com/showdeck.io/static/placeholders/venue_placeholder.png','content':'End-on Proscenium auditorium','capacity':'Capacity: 2800'},{'id':2,'name':'The Drum','image':'https://s3.eu-west-1.amazonaws.com/showdeck.io/static/placeholders/venue_placeholder.png','content':'Studio Theatre','capacity':'Capacity: 170'},{'id':3,'name':'Cold Lagoon','image':'https://s3.eu-west-1.amazonaws.com/showdeck.io/static/placeholders/venue_placeholder.png','content':'Room 1000','capacity':'Capacity: 75'},{'id':4,'name':'Room 1000','image':'https://s3.eu-west-1.amazonaws.com/showdeck.io/static/placeholders/venue_placeholder.png','content':'Rehearsal space','capacity':'Capacity: 50'}]
         }
    },
    methods: {
    
    isBioLong(bio) {
      //  This prevents rendering the 'See More/Less' buttons
      //  when they are not needed.
      return bio?.length > 500;
    },
    renderEllipses(bio) {
      //  Shows ellipses if bio is longer than 500 chars
      if (this.isBioLong(bio)) {
        return "..."
      } else {
        return;
      }
    },
  },
}
</script>