<template>
  <div class="mb-8 ">
    <main class="grid grid-cols-10">
      <div class="w-full grid col-span-10 bg-white pt-8 dark:bg-primaryDark">
     <PlaceHeader :headerInfo="headerInfo" :editMode="false">
      <ProfileNavbar class="col-start-3 col-span-6" @change="changeComponent" :navigation="navigation" version="2"/>
      <!-- <button v-if="isCreator" @click="enterEdit"
        class="flex mx-auto items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-primaryDark dark:text-white">Enter
        edit mode</button> -->
      </PlaceHeader>
      </div>
      <div class="col-start-0 col-span-10 md:col-start-2 mt-8 md:col-span-8 lg:col-start-3 lg:col-span-7 opacity-60">
        <transition name="fade">
          <component :is="currentComponent" :venue="venue" />
        </transition>
        </div>
    </main>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref, onBeforeMount } from "vue";
import { useRouter, useRoute } from "vue-router";
import PlaceHeader from '@/components/places/components/placeHeader/Header';
import Overview from "@/components/places/view/Overview";
import ProfileNavbar from "@/components/widgets/profileNavbar/ProfileNavbar";
import PlaceMembers from '@/components/places/view/PlaceMembers'
import PlaceSpace from '@/components/places/view/PlaceSpace'

// Navigation Options
const navigation = ref([
  { name: 'Overview', onClick: function () { onNavClick(this, navigation.value) }, component: 'Overview', current: true },
  { name: 'Productions', onClick: function () { onNavClick(this, navigation.value) }, component: 'PlaceMembers', current: false },
  { name: 'People', onClick: function () { onNavClick(this, navigation.value) }, component: 'PlaceSpace', current: false },
  { name: 'Spaces', onClick: function () { onNavClick(this, navigation.value) }, component: 'PlaceSpace', current: false },
])

function onNavClick(activeNode, navigation) {
  for (let i = 0; i < navigation.length; i++) { navigation[i].current = false }
  activeNode.current = true
}

export default {
  name: "Venue",
  components: { ProfileNavbar, Overview, PlaceHeader, PlaceMembers, PlaceSpace },

  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    // Initial setup
    onBeforeMount(async () => {
      await store.dispatch('getVenue', route.params.id)
    });
    let currentComponent = ref('Overview')
    const venue = computed(() => {
      return {
        ...store.getters.getVenue
      }
    })

    const routeId = computed(() => route.params.id);

    return {
      routeId,
      store,
      currentComponent,
      venue,
      navigation,
      headerInfo: computed(() => store.getters.getVenueHeader),
      enterEdit() {
        router.push({ name: 'EditVenue', params: { id: venue.value.id } })
      },
      isCreator: computed(() => store.getters.getId === store.getters.getVenueCreatedBy),

      changeComponent(component) {
        currentComponent.value = component
        localStorage.set("venueTab", component);
      },
      async beforeRouteUpdate(to, from, next) {
        await this.store.dispatch("getVenue", to.params.id);
        localStorage.set("venueTab", "Index");
        this.currentComponent = "Index"
        for (let i = 0; i < this.navigation.length; i++) {
          this.navigation[i].current = false;
        }
        this.navigation[0].current = true;
        next();
      },
      beforeUnmount() {
        // Reset navbar indicator
        for (let i = 0; i < this.navigation.length; i++) {
          this.navigation[i].current = false;
        }
        this.navigation[0].current = true;
      },
    }
  }
}
</script>
